import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, takeUntil, tap } from 'rxjs/operators';

import { Comparator, Comparators } from './comparators';
import { Filter, UrlFilterFacadeConfig } from './models';


/**
 * Filter-Facade that reads and writes filter-states using the URL as its single source of truth.
 *
 * This is done in the following way:
 *
 * 1. Read the current URL query-params and convert them to a filter-state.
 *    During this step, the query-params can be enriched further (e.g. using fallback values)
 * >_To customize how filter-states are extracted from the URL, **override the {@link readFilterStateFromURL} method.**_
 *
 * 2. Check if the parsed filter-state differs from the current filter-state.
 * >_To customize how filter-states are compared, **override the {@link UrlFilterFacadeConfig.filterStateComparator} property.**_
 *
 *
 * 3. If the filter-state differs, update the current filter-state.
 *    Also, if the {@link UrlFilterFacadeConfig.autoWritebackToUrl} flag is set (set by default),
 *    write the current (possibly enriched) filter-state back to the URL.
 * >_To customize how filter-states are written back to the URL, **override the {@link writeFilterStateToURL} method.**_
 * >_To customize whether filter-states are written back to the URL,
 * **override the {@link UrlFilterFacadeConfig.autoWritebackToUrl} property.**_
*/
export abstract class UrlFilterFacade<T extends Filter> {

  /**
   * `shareReplay(1)`- Observable that emits the current filter state (read from the URL).
  */
  readonly filter$: Observable<T>;
  /**
   * Convenience field for getting the current value of {@link filter$}.
   *
   * **Note:**
   *
   * Please note that since this property is based on a `shareReplay(1)`- Observable, but not a `BehaviorSubject`,
   * its value will be `undefined` until the URL was read for the first time.
   *
   * For convenience, this property is still typed using `T` (not `T?`).
   */
  filter!: T;

  private readonly isDestroyed$ = new Subject<void>();

  private readonly route: ActivatedRoute;
  private readonly router: Router;

  protected readonly autoWritebackToUrl: boolean;

  constructor ({ routing, filterStateComparator, autoWritebackToUrl }: UrlFilterFacadeConfig<T>) {

    this.route = routing.route;
    this.router = routing.router;
    this.autoWritebackToUrl = autoWritebackToUrl ?? true;

    this.filter$ = this.subscribeURLListener(this.route, filterStateComparator ?? Comparators.ByReference);
  }


  /* -- Hook Methods: -- */

  /**
   * Hook that reads the current filter from the URL params.
   * If any URL-keys need to be mapped to different keys in the filter,
   * or any default values need to be set, this hook method needs to be overridden.
   *
   * @example
   * ```ts
   * type FilterType = { page: number, pageSize: number };
   * class MyFilterFacade extends UrlFilterFacade<FilterType> {
   *
   *   override protected readFilterStateFromURL(paramMap: ParamMap): FilterType {
   *     // Setzt Standardwerte für die Paging-Parameter.
   *     return {
   *       page: Number(paramMap.get('page') ?? 1),
   *       pageSize: Number(paramMap.get('pageSize') ?? 50)
   *     };
   *   }
   *
   * }
   * ```
  */
  protected abstract readFilterStateFromURL(paramMap: ParamMap): T;

  /**
   * Hook that updates the URL params using the given filter.
   * If any filter-keys need to be mapped to different keys in the URL,
   * or any default values need to be set, this hook method needs to be overridden.
   *
   * This method gets called automatically, every time a **new** filter was read from the URL
   * (assuming {@link UrlFilterFacadeConfig.autoWritebackToUrl} is set to `true`).
   *
   * @example
   * ```ts
   * type FilterType = { page: number, pageSize: number };
   * class MyFilterFacade extends UrlFilterFacade<FilterType> {
   *
   *   override protected writeFilterStateToURL(filter: T, router: Router, route: ActivatedRoute): void {
   *     // Verhindert, dass Standardwerte in die URL geschrieben werden.
   *     const queryParams = {
   *       page: filter.page === 1 ? null : filter.page,
   *       pageSize: filter.page === 50 ? null : filter.pageSize,
   *     };
   *
   *     router.navigate([], { relativeTo: route, replaceUrl: true, queryParams });
   *   }
   *
   * }
   * ```
  */
  protected abstract writeFilterStateToURL(filter: T, router: Router, route: ActivatedRoute): void;

  /* -- Public Methods: -- */

  /** Method that updates the URL params (instead of directly manipulating the filter state). */
  updateFilter(partial: Partial<T>): void {
    this.writeFilterStateToURL({ ...(this.filter ?? {} as T), ...partial }, this.router, this.route);
  }

  setFilter(filter: T): void {
    this.writeFilterStateToURL(filter, this.router, this.route);
  }


  /* -- Internal Methods: -- */

  /** Subscribes the facade to the router. */
  private subscribeURLListener(
    { queryParamMap: queryParamMap$ }: ActivatedRoute,
    filterStateComparator: Comparator<T>
  ): Observable<T> {
    return queryParamMap$.pipe(
      map(paramMap => this.readFilterStateFromURL(paramMap)),
      filter(urlParamFilter => this.filter ? !filterStateComparator(urlParamFilter, this.filter) : true),
      shareReplay(1),
      tap(filter => { this.filter = filter; }),
      tap(filter => { if (this.autoWritebackToUrl) { this.writeFilterStateToURL(filter, this.router, this.route); } }),
      takeUntil(this.isDestroyed$),
    );
  }

  /** Unsubscribes the facade from the router. */
  unsubscribeURLListener(): void {
    this.isDestroyed$.next();
    if (!this.isDestroyed$.closed) {
      this.isDestroyed$.complete();
    }
  }

}
