import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ParamMap,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';

import { RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';

export const ROUTER_FEATURE_KEY = 'router';
export const SYSTEM_ROUTE = '/system';

export type RouterState = RouterReducerState<RouterStateUrl>;

export interface RouterPartialState {
  readonly [ROUTER_FEATURE_KEY]: RouterReducerState<RouterStateUrl>;
}
export interface RouterStateUrl {
  url: string;
  queryParamMap: ParamMap;
  paramMap: ParamMap;
  isSystemRoute: boolean;
}

export const activatedRoute = (router: Router): ActivatedRoute => {
  let route = router.routerState.root;
  while (route.firstChild) {
    route = route.firstChild;
  }

  return route;
};

@Injectable()
export class PlatformRouterSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParamMap } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { paramMap } = state;

    const isSystemRoute = url.toLowerCase().startsWith(SYSTEM_ROUTE);

    return { url, queryParamMap, paramMap, isSystemRoute };
  }
}
