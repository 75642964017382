export enum RelationEntityState {
  UNCHANGED = 'Unchanged',
  ADDED = 'Added',
  REMOVED = 'Removed'
}

export interface RelationEntity<T> {
  initialState: RelationEntityState;
  state: RelationEntityState;
  entity: T;
}
