import { AuthConfig } from 'angular-oauth2-oidc';

import { AuthConfigWithRequiredFields } from '../models/auth-config';

export const authConfigHasRequiredFields = (configObject: AuthConfig): configObject is AuthConfigWithRequiredFields => {
  const config: AuthConfig = configObject;

  if (!config) {
    return false;
  }

  return config.issuer != null && config.clientId != null && config.scope != null && config.responseType === 'code';
};
