import { Injectable } from '@angular/core';
import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { EMPTY, Observable, ReplaySubject, from } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { redirectToErrorPage } from '@core/shared/util';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  onSessionError$?: Observable<OAuthEvent>;
  onSessionTerminated$?: Observable<OAuthEvent>;

  private readonly _userSuccessfullyLoggedIn$: ReplaySubject<void> = new ReplaySubject(1);
  userSuccessfullyLoggedIn$: Observable<void> = this._userSuccessfullyLoggedIn$.asObservable();

  constructor(private readonly oauthService: OAuthService) {}

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  setupUsingOAuthConfiguration(authConfig: AuthConfig): void {
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.setupSessionEventHandlers();
  }

  private setupSessionEventHandlers(): void {
    this.onSessionTerminated$ = this.oauthService.events.pipe(filter((event) => event.type === 'session_terminated'));

    this.onSessionError$ = this.oauthService.events.pipe(filter((event) => event.type === 'session_error'));
  }

  executeCodeFlowWithState<S>(state: S): Observable<S | undefined> {
    return from(
      this.oauthService.loadDiscoveryDocumentAndTryLogin().then((success) => {
        if (success) {
          if (!this.oauthService.hasValidAccessToken()) {
            // Note: This will actually **exit** the app if no token is present
            this.oauthService.initCodeFlow(state === undefined ? undefined : JSON.stringify(state));
          } else {
            this._userSuccessfullyLoggedIn$.next();
            this._userSuccessfullyLoggedIn$.complete();
            return this.oauthService.state ? (JSON.parse(decodeURIComponent(this.oauthService.state)) as S) : state;
          }
        }

        return undefined;
      }),
    )
      .pipe(
        catchError(() => {
          // Error-Handling: Unavailable authorization server
          redirectToErrorPage();

          return EMPTY;
        }),
      );
  }

  logout(noRedirectToLogoutUrl = false): void {
    this.oauthService.logOut(noRedirectToLogoutUrl);
  }
}
