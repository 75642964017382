import { Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { catchError, filter, map, shareReplay, tap } from 'rxjs/operators';

import { redirectToErrorPage } from '@core/shared/util';

import { ConfigLoaderService } from './config-loader.service';
import { AppInsightsConfig, EnvironmentConfig, appInsightsConfigHasRequiredFields } from './models';
import { authConfigHasRequiredFields, envConfigHasRequiredFields } from './utils';

@Injectable()
export class ConfigLoaderFacade {
  authConfig$: Observable<AuthConfig> = this.configLoaderService.loadAuthConfig().pipe(
    tap((authConfig) => this.validateAuthConfig(authConfig)),
    map((authConfig) => this.adjustAuthConfigBasedOnOrigin(authConfig)),
    shareReplay(1),
  );

  environmentConfig$: Observable<EnvironmentConfig> = this.configLoaderService.loadEnvironmentConfig().pipe(
    tap((appConfig) => this.validateEnvironmentConfig(appConfig)),
    catchError((err: unknown) => {
      // Error-Handling: Missing or invalid config-file
      // TODO: Nur eine Zwischenlösung! Fehlerseite soll Fehler in Zukunft aufbereiten und anzeigen.
      redirectToErrorPage(-1);
      throw err;
    }),
    shareReplay(1),
  );

  appInsightsConfig$: Observable<AppInsightsConfig> = this.configLoaderService.loadAppInsightsConfig().pipe(
    filter((appInsightsConfig) => appInsightsConfigHasRequiredFields(appInsightsConfig)),
    shareReplay(1),
  );

  constructor(private readonly configLoaderService: ConfigLoaderService) {}

  private validateAuthConfig(authConfig: AuthConfig): void {
    if (authConfigHasRequiredFields(authConfig)) {
      return;
    }

    throw new Error('Auth config is invalid!');
  }

  private validateEnvironmentConfig(environmentConfig: EnvironmentConfig): void {
    if (envConfigHasRequiredFields(environmentConfig)) {
      return;
    }

    throw new Error('Environment config is invalid!');
  }

  private adjustAuthConfigBasedOnOrigin(authConfig: AuthConfig): AuthConfig {
    const defaultRedirectUri = `${location.origin}/`;

    return {
      ...authConfig,
      redirectUri: defaultRedirectUri,
    };
  }
}
