import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalStorageService } from '@core/shared/util';

@Injectable()
export class ActiveOrganisationInterceptor implements HttpInterceptor {
  constructor(private readonly storageService: LocalStorageService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.storageService.isAvailable()) {
      throw new Error('"activeOrganisationId" could not be read because localstorage is deactivated for this site.');
    }

    const activeOrganisationId: string | null = this.storageService.readString('activeOrganisationId', null);

    return next.handle(
      activeOrganisationId ? this.attachHeadersToRequest(req, activeOrganisationId) : this.simplyForwardRequest(req),
    );
  }

  private attachHeadersToRequest(request: HttpRequest<unknown>, activeOrganisationId: string): HttpRequest<unknown> {
    const requestWithHeader = request.clone({
      setHeaders: { 'x-active-organisation': activeOrganisationId.toString() },
    });

    return requestWithHeader;
  }

  private simplyForwardRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
    return request;
  }
}
