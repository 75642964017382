import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, map } from 'rxjs';

import { createAppTitle } from '@core/shared/util';

import { AppConfigService } from '../config-loader/app-config.service';

export function tabTitleResolver(featureTitle: string): ResolveFn<string> {
  return (): Observable<string> =>
    inject(AppConfigService).envConfig$.pipe(map(({ appTitle }) => createAppTitle(appTitle, featureTitle)));
}
