type Primitive = string | number | boolean | undefined;
/**
 * Lambda function to compare two states.
 * Returns `true` if the states are equal.
*/
export type Comparator<T> = (a: T, b: T) => boolean;
export type ComparatorTemplate<T> = { [Key in keyof T]-?: Comparator<T[Key]> | null };

export namespace Comparators {

  /** Compares two values by reference:
   *
   * `a === b` */
  export const ByReference = <T>(a: T, b: T) => a === b;

  /** Compares two values by value:
   *
   * `a === b` */
  export const ByValue = <T = Primitive>(a: T, b: T) => a === b;

  /** Compares two values by value, but allows casting beforehand:
   *
   * `a == b` */
  // eslint-disable-next-line eqeqeq
  export const ByFuzzyValue = <T = Primitive>(a: T, b: T) => a == b;

  /** Compares two arrays by comparing their lengths and entries by value (see {@link Comparators.ByValue}).
   *
   * `a.length === b.length && a.every((v, i) => v === b[i])`
   * */
  export const PrimitiveArray = <T extends Primitive>(a: Array<T> | undefined, b: Array<T> | undefined) =>
    a?.length === b?.length &&
    (a && b ? a.every((v, i) => v === b[i]) : a === b);

  /** Allows for the construction of `Comparator` instances,
   * that combine multiple comparers for the application on complex objects.
   * In order to do so, individual comparers can be assigned to individual fields. */
  export function ForType<T extends object>(comparers: ComparatorTemplate<T>): Comparator<T> {
    return (a: T, b: T) => {

      for (const key in comparers) {
        const currentComparer = comparers[key];
        if (currentComparer) {
          // Case 1: Comparer was defined by user -> use it
          if (!currentComparer(a[key], b[key])) {
            return false;
          }
        } else if (currentComparer === null) {
          // Case 2: Comparer was explicitly set to `null` -> ignore field
          continue;
        }
      }

      return true;
    };
  }
}
