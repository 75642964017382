import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppInsightsConfig, EnvironmentConfig } from './models';

export const ENVIRONMENT_CONFIG_API_PATH = '/api/config/environment';

export const AUTH_CONFIG_DIRECTORY = 'config/auth.config.json';

export const APP_INSIGHTS_CONFIG_DIRECTORY = 'config/app-insights.config.json';

@Injectable()
export class ConfigLoaderService {
  constructor(private readonly httpClient: HttpClient) {}

  loadAuthConfig(): Observable<AuthConfig> {
    return this.httpClient.get<AuthConfig>(AUTH_CONFIG_DIRECTORY).pipe(
      catchError(() => {
        // Error-Handling: Missing config-file
        throw new Error('Auth config fehlt!');
      }),
    );
  }

  loadEnvironmentConfig(): Observable<EnvironmentConfig> {
    return this.httpClient.get<EnvironmentConfig>(ENVIRONMENT_CONFIG_API_PATH).pipe(
      catchError(() => {
        // Error-Handling: Missing config-file
        throw new Error('Environment config fehlt!');
      }),
    );
  }

  loadAppInsightsConfig(): Observable<AppInsightsConfig> {
    return this.httpClient.get<AppInsightsConfig>(APP_INSIGHTS_CONFIG_DIRECTORY).pipe(
      catchError(() => {
        // Error-Handling: Missing config-file
        throw new Error('App insights config fehlt!');
      }),
    );
  }
}
